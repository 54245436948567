import React, {
    ChangeEvent,
    useCallback,
    useEffect,
    useRef,
    useState,
} from 'react'
import InfiniteScroll from 'react-infinite-scroll-component'
import { Route, Routes, useLocation } from 'react-router-dom'
import { animated, useTransition } from 'react-spring'
import SearchIcon from '../../assets/images/svgs/search.svg'
import { Header } from '../../components/header'
import { Modal } from '../../components/modal'
import Photos from '../../components/photos'
import ShopScreenButton from '../../components/shop-screen-button'
import {
    filterCategories,
    filterLoginedCategories,
    getAllAdditionalDefaultPhotos,
    getAllAdditionalPhotos,
    getAllDefaultPhotos,
    getAllPhotos,
    getCategories,
    searchAll,
    searchDefault,
} from '../../services/api'
import { useAppSelector } from '../../store/hooks'
import { Category, CorporatePartner } from '../../types/api'
import { useTranslation } from 'react-i18next'
import './styles.scss'
// import Filters from "../../components/filters";
let scrollWidth: number

const ShopScreen = () => {
    const { user, access_token } = useAppSelector((state) => state.auth)
    const { error } = useAppSelector((state) => state.impact)
    const {t} = useTranslation()
    const [photos, setPhotos] = useState<Array<CorporatePartner>>([])
    const [categories, setCategories] = useState<Array<Category>>([])
    const [activeCategory, setActiveCategory] = useState<any>({
        id: '1',
        name: 'All',
        sort_order: 0,
    })
    const [limit, setLimit] = useState(16)
    const [offset, setOffset] = useState(16)
    const [text, setText] = useState('')
    const [rewards, setRewards] = useState('On')
    const [filtersShow, setFiltersShow] = useState(false)
    const firstRun = useRef(true)

    const location = useLocation()
    const transitions = useTransition(location, {
        from: { opacity: 0 },
        enter: { opacity: 1 },
        leave: { opacity: 0 },
    })

    const _search = useCallback(async () => {
        if (text.length === 0) {
            if (activeCategory.id === '1') {
                setActiveCategory({ id: '1', name: 'All', sort_order: 0 })
            } else {
                const data = await _filterCategories(
                    activeCategory.id,
                    limit,
                    0
                )
                setPhotos([...data])
            }
        } else {
            if (user) {
                const data = await searchAll(
                    access_token.access_token,
                    text,
                    limit,
                    0
                )
                setPhotos(data)
            } else {
                const data = await searchDefault(text, limit, 0)
                setPhotos(data)
            }
        }
    }, [photos, activeCategory, text])
    // const _reward = () => {
    //     rewards === "Off" ? setRewards("On") : setRewards("Off")
    // }
    // const _setFiltersShow = () => {
    //     filtersShow ? setFiltersShow(false) : setFiltersShow(true);
    // }
    const search_delete = () => {
        setText('')
        setActiveCategory({ id: '1', name: 'All', sort_order: 0 })
    }

    const _inputSearch = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.charCode === 13) {
            _search()
        }
    }
    const _getAllCategories = useCallback(async () => {
        const data = await getCategories()
        data.splice(0, 0, { id: '1', name: 'All', sort_order: 0 })
        setCategories(data)
    }, [categories])

    const _getAllPhotos = useCallback(async () => {
        let data: Array<CorporatePartner> = []
        if (user) {
            data = await getAllPhotos(access_token.access_token, limit, 0)
        } else {
            data = await getAllDefaultPhotos(
                access_token.access_token,
                limit,
                0
            )
        }
        setPhotos(data)
    }, [photos, user, access_token])

    useEffect(() => {
        _getAllCategories()
    }, [])
    useEffect(() => {
        if (firstRun.current) {
            firstRun.current = false
        } else {
            setOffset(() => 16)
            setActiveCategory({ id: '1', name: 'All', sort_order: 0 })
        }
    }, [user])

    useEffect(() => {
        _getFilteredPhotoByCategory()
    }, [activeCategory])

    const _filterCategories = useCallback(
        async (id: string, limit: number, offset: number) => {
            let data: any = []
            if (user) {
                data = filterLoginedCategories(
                    id,
                    limit,
                    offset,
                    access_token.access_token
                )
            } else {
                data = filterCategories(id, limit, offset)
            }
            return data
        },
        [user, categories, activeCategory]
    )

    const _getAllAdditionalPhoto = async () => {
        let data: Array<CorporatePartner> = []
        if (!text) {
            if (activeCategory.name !== 'All') {
                if (user) {
                    setOffset((prev) => prev + 16)
                    const data = await filterLoginedCategories(
                        activeCategory.id,
                        limit,
                        offset,
                        access_token.access_token
                    )
                    setPhotos([...photos, ...data])
                } else {
                    setOffset((prev) => prev + 16)
                    const data = await filterCategories(
                        activeCategory.id,
                        limit,
                        offset
                    )
                    setPhotos([...photos, ...data])
                }
            } else {
                if (user) {
                    setOffset((prevState) => prevState + 16)
                    data = await getAllAdditionalPhotos(
                        access_token.access_token,
                        limit,
                        offset
                    )
                    setPhotos([...photos, ...data])
                } else {
                    setOffset((prev) => prev + 16)
                    data = await getAllAdditionalDefaultPhotos(
                        access_token.access_token,
                        limit,
                        offset
                    )
                    setPhotos([...photos, ...data])
                }
            }
        } else {
            if (user) {
                data = await searchAll(
                    access_token.access_token,
                    text,
                    limit,
                    offset
                )
                setPhotos([...photos, ...data])
                setOffset(offset + 16)
            } else {
                data = await searchDefault(text, limit, offset)
                setPhotos([...photos, ...data])
                setOffset(offset + 16)
            }
        }
    }

    const fetchData = () => {
        console.log('yes')
        _getAllAdditionalPhoto()
    }
    const _chooseItem = (el: Category) => {
        setOffset(0)
        setActiveCategory(el)
    }

    const _getFilteredPhotoByCategory = async () => {
        setPhotos([])
        if (activeCategory.id == 1) {
            setOffset((prev) => prev + 16)
            _getAllPhotos()
        } else {
            const data = await _filterCategories(
                activeCategory.id,
                limit,
                offset
            )
            setPhotos(data)
            setOffset((prev) => prev + 16)
        }
    }
    // useEffect(() => {
    //     if (text.length >= 3){
    //         _search()
    //     } else if(text.length === 0){
    //         _getFilteredPhotoByCategory()
    //     }
    // }, [text])
    const _handlerChange = (e: ChangeEvent<HTMLInputElement>) => {
        setText(e.target.value)
    }
    useEffect(() => {
        const div = document.createElement('div')
        div.style.overflowY = 'scroll'
        div.style.width = '50px'
        div.style.height = '50px'
        document.body.append(div)
        scrollWidth = div.offsetWidth - div.clientWidth
        div.remove()
    }, [])

    return (
        <InfiniteScroll
            dataLength={photos ? photos.length : 16}
            next={fetchData}
            hasMore={true}
            loader={null}
            endMessage={null}
            scrollableTarget="scrollableDiv"
        >
            <div
                id="content"
                className={
                    location.pathname !== '/'
                        ? 'shop-screen_disabled'
                        : 'shop-screen'
                }
            >
                <Header />
                {error ? (
                    <div
                        style={{
                            fontSize: '26px',
                            color: 'red',
                            fontWeight: 'bold',
                            textAlign: 'center',
                        }}
                    >
                        Something went wrong... Please, try later!
                    </div>
                ) : (
                    <>
                        <div className="shop-screen__input-container">
                            <div className="shop-screen__inputs">
                                <input
                                    className="shop-screen__input"
                                    type="text"
                                    value={text}
                                    onChange={_handlerChange}
                                    placeholder={t("search_brands")}
                                    autoComplete="off"
                                    onKeyPress={_inputSearch}
                                />
                                <img
                                    className="shop-screen_icon"
                                    src={SearchIcon}
                                />
                                {text && (
                                    <span
                                        onClick={search_delete}
                                        className="shop-screen_search-delete"
                                    >
                                        X
                                    </span>
                                )}
                                <ShopScreenButton
                                    text={t("search")}
                                    _className={'shop-screen-button-search'}
                                    id={'20'}
                                    onClick={_search}
                                />
                                {/* <div className="shop-screen__rewards-container">
                            {rewards==="Off" && <div className="shop-screen__rewards-container__text">{rewards}</div>}
                             <ShopScreenButton
                                text="Rewards"
                                _className={"shop-screen-button-search"}
                                id={"20"}
                                onClick={_reward}
                            />
                            {rewards==="On" && <div className="shop-screen__rewards-container__text">{rewards}</div>}
                        </div>  */}
                            </div>
                            <div className="shop-screen__buttons">
                                {/* <img className="shop-screen__buttons_filter-icon" src={FilterIcon} onClick={_setFiltersShow}/> */}
                                {categories?.map((e: Category) => {
                                    return (
                                        <ShopScreenButton
                                            key={e.id}
                                            id={e.id}
                                            el={e}
                                            text={e.name}
                                            _className={
                                                activeCategory.name === e.name
                                                    ? 'shop-screen-button-active'
                                                    : 'shop-screen-button'
                                            }
                                            onClick={_chooseItem}
                                        />
                                    )
                                })}
                            </div>
                        </div>
                        <div className="shop-screen__photos-container">
                            {/* {filtersShow && <Filters setFiltersShow={setFiltersShow}/>} */}
                            <div className="shop-screen__photos">
                                {photos &&
                                    photos?.map((e: CorporatePartner) => {
                                        return (
                                            <Photos
                                                key={e.id}
                                                data={e}
                                                text={text}
                                                isFiltersShow={filtersShow}
                                            />
                                        )
                                    })}
                            </div>
                        </div>
                    </>
                )}

                {transitions((props, item) => (
                    <animated.div style={props} className="animated">
                        <Routes location={item}>
                            <Route path="/" element={false} />
                            <Route path="/menu" element={<Modal />} />
                            <Route
                                path="/brand/:brandName"
                                element={<Modal />}
                            />
                        </Routes>
                    </animated.div>
                ))}
            </div>
        </InfiniteScroll>
    )
}

export default ShopScreen
