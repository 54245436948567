import React from 'react'
interface Props {
    text: string
    onClick: () => void
}
export const Menu_buttons: React.FC<Props> = ({ text, onClick }) => {
    return (
        <div onClick={onClick}>
            <button className="menu__buttons">
                <div className="menu__buttons__text">{text}</div>
            </button>
        </div>
    )
}
