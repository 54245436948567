import React from 'react'
import './styles.scss'
interface Props {
    text: string
    arrow?: string
    onClick: () => void
}
export const SettingsButton: React.FC<Props> = ({ text, arrow, onClick }) => {
    return (
        <button onClick={onClick} className="button-container">
            <div className="button-container__icon-text">
                <div className="button-container__text">{text}</div>
            </div>
            {arrow && (
                <div className="button-container__arrow">
                    <img src={arrow} />
                </div>
            )}
        </button>
    )
}
