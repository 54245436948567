import { useTranslation } from 'react-i18next'

const MenuLinks = () => {
    const { t, i18n } = useTranslation()
    const currentLang = i18n.language
    const pathLang = currentLang === "en" ? "" : currentLang + "/"

    return (
        <>
            <a href={`https://www.pai.social/${pathLang}`}>{t('home')}</a>
            <a className="active-item" href="">
                {t('shop')}
            </a>
            <a href={`https://www.pai.social/${pathLang}savers`}>
                {t('savers')}
            </a>
            <a href={`https://www.pai.social/${pathLang}partners`}>
                {t('partners')}
            </a>
        </>
    )
}

export default MenuLinks
