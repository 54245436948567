import { useEffect, useState } from 'react'

import arrowBack from '../../assets/images/svgs/arrow-black.svg'
import arrow from '../../assets/images/svgs/arrow-curved.svg'
import penny from '../../assets/images/penny.png'
import { ImpactCard } from '../../components/impact-card'
import { ProjectCard } from '../../components/project-card'
import { getAllProjects } from '../../services/api'
import { changeScreen } from '../../store/auth/auth-action'
import { useAppDispatch, useAppSelector } from '../../store/hooks'
import { setInProject } from '../../store/impact/impact-action'
import { Project, User } from '../../types/api'
import './styles.scss'
import UserMenuHeader from '../../components/user-menu-header'
import { useTranslation } from 'react-i18next'

const MyImpact = () => {
    const access_token = useAppSelector((state) => state.auth.access_token)
    const user = useAppSelector((state) => state.auth.user) as User
    const { inProject } = useAppSelector((state) => state.impact)
    const [impacts, setImpacts] = useState<Array<Project>>([])
    const [isLoad, setIsLoad] = useState(false)
    const [activeTab, setActiveTab] = useState(
        inProject ? 'Projects' : 'Impact'
    )
    const dispatch = useAppDispatch()
    const _setActiveTab = (tab: string) => {
        setActiveTab(tab)
    }
    const {t} = useTranslation()

    const _goBack = () => {
        dispatch(changeScreen('Menu Screen'))
    }
    const _getAllProjects = async () => {
        const data = await getAllProjects(access_token.access_token)
        setImpacts(data)
        setIsLoad(true)
    }

    useEffect(() => {
        _getAllProjects()
    }, [])

    const myImpacts = impacts.filter((impact) => impact.user_total_units_amount)

    const _renderImpacts = () => {
        const currentImpacts = activeTab === 'Impact' ? myImpacts : impacts

        return (
            <>
                {currentImpacts && (
                    <div className="impact-container__cards-wrapper">
                        {currentImpacts.map((item: Project) =>
                            activeTab === 'Impact' ? (
                                <ImpactCard key={item.id} item={item} />
                            ) : (
                                <ProjectCard key={item.id} item={item} />
                            )
                        )}
                    </div>
                )}
            </>
        )
    }

    return (
        <div className="impact-container">
            <UserMenuHeader isBackArrow onClose={_goBack} />
            <div className="impact-container__content">
                <div className="impact-container__switch-container">
                    <div className="impact-container__switch-wrapper">
                        <div
                            className={`impact-container__switcher ${
                                activeTab === 'Impact' && 'active-switcher'
                            }`}
                            onClick={() => {
                                _setActiveTab('Impact')
                                dispatch(setInProject(false))
                            }}
                        >
                            <p className="impact-container_switcher-text">
                                {t("savings")}
                            </p>
                        </div>
                        <div
                            className={`impact-container__switcher ${
                                activeTab === 'Projects' && 'active-switcher'
                            }`}
                            onClick={() => {
                                _setActiveTab('Projects')
                                dispatch(setInProject(true))
                            }}
                        >
                            <p className="impact-container_switcher-text">
                            {t("goals")}
                            </p>
                        </div>
                    </div>
                </div>
                <h3 className="title-tab">
                    {activeTab === 'Impact'
                        ? t("my_savings")
                        : t("pension_contributions")}
                </h3>
                <div className="impact-container__content-wrapper">
                    {_renderImpacts()}
                </div>
                {/* {isLoad && myImpacts.length === 0 && activeTab === 'Impact' && (
                    <>
                        <img
                            alt="arrow"
                            src={arrow}
                            className="impact-container__no-projects-img"
                        />
                        <h3 className="impact-container__no-projects">
                            Support a project today and watch your impact grow
                            here
                        </h3>
                    </>
                )} */}
            </div>
        </div>
    )
}

export default MyImpact
