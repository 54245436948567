import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import en from './translation/en.json'
import se from './translation/se.json'
import nl from './translation/nl.json'

const resources = {
    en: {
        translation: en,
    },
    se: {
        translation: se,
    },
    nl: {
        translation: nl,
    },
}

i18n.use(initReactI18next).init({
    resources,
    lng: 'en',
    fallbackLng: 'en',
    interpolation: {
        escapeValue: false,
    },
})

export default i18n
