import SettingsHeader from '../../components/settings-header'
import { useTranslation } from 'react-i18next'
import './styles.scss'

const TermsOfUse = () => {
    
    const {t} = useTranslation()
    
    return (
        <div className="terms-of-use__container">
            <SettingsHeader screen="Settings" text={t("terms_of_use")} />
        </div>
    )
}

export default TermsOfUse
