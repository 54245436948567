import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import langSvg from '../../assets/images/svgs/lang.svg';
import "./styles.scss"

const languages = [
    { code: 'en', name: 'ENG', label: 'ENGLISH' },
    { code: 'se', name: 'SE', label: 'SVENSKA' },
    { code: 'nl', name: 'NL', label: 'DUTCH' }
];

export const LanguageDropdown = () => {
    const { i18n } = useTranslation();
    // const [selectedLang, setSelectedLang] = useState(languages[0]);
    const [selectedLang, setSelectedLang] = useState(() => {
        const savedLang = localStorage.getItem('selectedLanguage');
        return savedLang ? JSON.parse(savedLang) : languages[0];
    });
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const navigate = useNavigate();
   
    const handleLanguageChange = (lang: typeof languages[0]) => {
        setSelectedLang(lang);
        setIsDropdownOpen(false);
        i18n.changeLanguage(lang.code);
        localStorage.setItem('selectedLanguage', JSON.stringify(lang));
        const langPath = lang.code === "en" ? "" : lang.code 
        navigate(`/${langPath}`);
    };

    return (
        <div className="language-dropdown" 
             onMouseEnter={() => setIsDropdownOpen(true)} 
             onMouseLeave={() => setIsDropdownOpen(false)}>
            <div className="language-dropdown__button">
                <img src={langSvg} alt="Language" />
                <h5>{selectedLang.name}</h5>
                <div className="arrow-down"></div>
            </div>
            {isDropdownOpen && (
                <ul className="language-dropdown__list">
                    {languages.map(lang => (
                        <li key={lang.code} onClick={() => handleLanguageChange(lang)}>
                            {lang.label}
                        </li>
                    ))}
                </ul>
            )}
        </div>
    );
};
