import React from 'react'
import { Button } from '../button'
import { useTranslation } from 'react-i18next'
import './styles.scss'

interface Props {
    hideInfo: () => void
}

const TransactionInfo: React.FC<Props> = ({ hideInfo }) => {
    
    const {t} = useTranslation()
    
    return (
        <div className="transaction-info-container">
            <div className="transaction-info-container__modal">
                <h3 className="transaction-info-container_title">
                    {t("transactions")}
                </h3>
                <p className="transaction-info-container_desc">
                    Card-linked transactions will appear within a few minutes.
                    Other (Online) transactions may take up to an hour.
                </p>
                <Button variant="white-outlined" text="OK" onClick={hideInfo} />
            </div>
        </div>
    )
}

export default TransactionInfo
