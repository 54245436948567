import { useNavigate } from 'react-router-dom'
import logo_black from '../../assets/images/LOGO_BLACK.png'
import close from '../../assets/images/svgs/close.svg'
import { Button } from '../../components/button'
import { changeScreen } from '../../store/auth/auth-action'
import { useAppDispatch } from '../../store/hooks'
import './styles.scss'

const StartScreen = () => {
    const dispatch = useAppDispatch()
    const navigate = useNavigate()

    const _closeModal = () => {
        navigate('/')
    }
    const _signUp = () => {
        dispatch(changeScreen('Sign Up'))
    }

    const _signIn = () => {
        dispatch(changeScreen('Sign In'))
    }

    return (
        <div className="container">
            <div className="container__close-wrapper">
                <img
                    className="container-close"
                    onClick={_closeModal}
                    src={close}
                />
            </div>
            <div className="container__body">
                <div className="container__logo-container">
                    <img src={logo_black} className="container_logo" />
                </div>
                <div className="container__sign-up-wrapper">
                    <Button
                        variant="white-outlined"
                        text="Sign Up"
                        onClick={_signUp}
                    />
                </div>

                <p className="container_already-text">
                    Already have an account?{' '}
                    <span onClick={_signIn} className="container_sign-in">
                        Sign in
                    </span>
                </p>
            </div>
        </div>
    )
}

export default StartScreen
