import React from 'react'
import { useNavigate } from 'react-router-dom'

import { changeScreen } from '../../store/auth/auth-action'
import { setIsInScreen } from '../../store/following/following-action'
import { useAppDispatch, useAppSelector } from '../../store/hooks'
import { setActiveCorporateId } from '../../store/impact/impact-action'
import { CorporatePartner } from '../../types/api'
interface Props {
    data: CorporatePartner
    text: string
    isFiltersShow: boolean
}
const Photos: React.FC<Props> = ({ data, isFiltersShow }) => {
    const { user } = useAppSelector((state) => state.auth)
    const dispatch = useAppDispatch()
    const navigate = useNavigate()

    const openDetails = () => {
        if (user) {
            dispatch(setIsInScreen(false))
            dispatch(setActiveCorporateId(data.id))
            dispatch(changeScreen('Corporate Details'))
            navigate('/brand/' + data.name.replaceAll(' ', '_'))
        } else {
            dispatch(changeScreen('Start Screen'))
            navigate('/brand/' + data.name.replaceAll(' ', '_'))
        }
    }

    return (
        <div
            onClick={openDetails}
            className={
                isFiltersShow
                    ? 'shop-screen__photo-container-true'
                    : 'shop-screen__photo-container-false'
            }
        >
            <div className="shop-screen__img-container">
                <picture>
                    <img
                        className="shop-screen_photo"
                        src={data?.photo?.url}
                        alt=""
                    />
                </picture>
            </div>
            {!data?.hide_name && (
                <div className="shop-screen__photo-name">{data?.name}</div>
            )}
        </div>
    )
}

export default Photos
