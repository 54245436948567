import React from 'react'
import './styles.scss'

interface Props {
    text?: string | null
    icon?: string
    image?: string
    hideImg?: boolean
    variant: string
    className?: string
    onClick: () => void
    disabled?: boolean
}

export const Button: React.FC<Props> = ({
    text,
    image,
    variant,
    onClick,
    icon,
    hideImg,
    className,
    disabled = false,
}) => {
    return (
        <>
            <button
                className={`${variant} button ${
                    disabled && 'disabled'
                } ${className}`}
                onClick={onClick}
            >
                <img className={`${variant}_icon`} src={icon} />
                <p className={`${variant}_title`}>{text}</p>
                {!hideImg && <img className={`${variant}_image`} src={image} />}
            </button>
        </>
    )
}
