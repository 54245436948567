import React from 'react'
import { Button } from '../../components/button'
import { deleteOwnAccount } from '../../services/api'
import { useAppSelector } from '../../store/hooks'
import './styles.scss'

interface Props {
    setDeleteAccount: (state: boolean) => void
}

export const DeleteAccount: React.FC<Props> = ({ setDeleteAccount }) => {
    const { access_token } = useAppSelector((state) => state.auth)

    const _back = () => {
        setDeleteAccount(false)
    }
    const _deleteOwnAccount = () => {
        deleteOwnAccount(access_token.access_token)
    }
    return (
        <div className="delete-account-container">
            <div className="delete-account-container__delete">
                <div className="delete-account-container__title">
                    DELETE ACCOUNT
                </div>
                <div className="delete-account-container__text">
                    Are you sure you want to delete account and all your data?
                </div>
                <div className="delete-account-container__buttons">
                    <div className="delete-account-container__button">
                        <Button
                            variant="white-outlined"
                            text="Delete"
                            onClick={_deleteOwnAccount}
                            disabled={false}
                        />
                    </div>
                    <div className="delete-account-container__button">
                        <Button
                            variant="white-outlined"
                            text="Cancel"
                            onClick={_back}
                            disabled={false}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}
